<template>
    <div class="home">
        <div class="vld-parent">
            <loading
                :active.sync="isLoading"
                :can-cancel="true"
                :height="100"
                :width="100"
                :opacity="0.3"
                :is-full-page="fullPage"></loading>
        </div>

        <navigation></navigation>

        <div id="container" style="margin-top: -110px">
            <div class="slider-wrap showcase om-section block-0" id="slider-9ae29c08a50641602b510d16b5084bcc">
                <ul
                    class="slider dots dots-default scroll-to scroll-to-double"
                    data-slider-speed="5000"
                    data-height="fullscreen"
                    data-att="#wpadminbar">
                    <li>
                        <figure>
                            <img alt="Brasserie Den Anker" src="../assets/img/DenAnker6-min.jpg">
                            <figcaption>Brasserie Den Anker</figcaption>
                        </figure>
                    </li>
                </ul>
                <div class="overlay-wrap fixed-center">
                    <section class="overlay">
                        <h1>Brasserie Den Anker</h1>
                        <!--                        <h2>Momenten</h2>-->
                        <!--                        <h3>Om nooit te vergeten</h3>-->
                        <p>
                            <router-link class="button" to="/reserveren">Reserveren</router-link>
                        </p>
                    </section>
                </div>

                <a class="scroll-to double" title="Lees meer"><span>Lees meer</span></a>
            </div>

            <div id="content-wrap" class="content-wrap content-gallery om-section block-1">
                <a class="anchor scrollto-anchor" id="scrollto-anchor-1"></a>
                <section class="content">
                    <div class="clearfix page-gallery-wrap gallery-position-right">
                        <div class="item-page-content-wrap">
                            <div class="item-page-content">
                                <h1 class="entry-title">
                                    <a href="/" title="Brasserie Den Anker">Brasserie Den Anker</a></h1>
                                <h1>{{ data[0].title }}</h1>
                                <h3>{{ data[0].subtitle }}</h3>
                                <p v-html="data[0].text"></p>
                                <!--                                <p>-->
                                <!--                                    <a class=button href=/impressies>Impressies</a>-->
                                <!--                                </p>-->
                            </div>
                        </div>
                        <div class="gallery-wrap">
                            <div class="clearfix">
                                <ul class="clearfix gallery type-3-columns portrait border-10">
                                    <li class="image-0">
                                        <figure>
                                            <a>
                                                <img alt="Brasserie Den Anker" src="../assets/img/DSC_6983-min.jpg">
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="image-1">
                                        <figure>
                                            <a>
                                                <img alt="Brasserie Den Anker" src="../assets/img/lights.jpeg">
                                            </a>
                                        </figure>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div class="masonry-wrap om-section block-2" id="masonry-2861e82813f5b4a39deb6f3e8b3fe480">
                <a class="anchor scrollto-anchor" id="scrollto-anchor-2"></a>
                <section class="masonry clearfix border-20 resp-h">
                    <ul class="block-wrap-2">
                        <li style="padding:15px" class="block-2 block-item-0 has-img zoom">
                            <a href="#">
                                <figure>
                                    <img alt="Brasserie Den Anker" src="../assets/img/entrance.jpeg">
                                </figure>
                            </a>
                        </li>

                        <li style="padding:15px" class="block-2 block-item-1 has-img overlay-visible">
                            <a title="Beleef">
                                <figure>
                                    <img alt="Brasserie Den Anker" src="../assets/img/hall.jpeg">
                                </figure>
                                <div class="content">
                                    <h3 style="color: #fff">Beleef</h3>
                                    <p style="color: #fff">de unieke sfeer</p>
                                </div>
                                <div class="hover"></div>
                            </a>
                        </li>

                        <li style="padding:15px" class="block-2 block-item-2 has-img zoom">
                            <a>
                                <figure>
                                    <img alt="Brasserie Den Anker" src="../assets/img/DSC_7002-min.jpg">
                                </figure>
                            </a>
                        </li>
                    </ul>
                </section>
                <div class="resp-h-nav"><a class="prev" href="#">&lt;</a><a class="next" href="#">&gt;</a></div>
            </div>

            <div
                class="content-wrap content-gallery om-section block-3"
                id="content-gallery-442c81685ceaa2ca525728b96d8e6878"><a
                class="anchor scrollto-anchor"
                id="scrollto-anchor-3"></a>
                <section class="content">
                    <div class="clearfix page-gallery-wrap gallery-position-left">
                        <div class="gallery-wrap">
                            <div class="clearfix gallery-wrap">
                                <ul class="clearfix gallery type-3-columns landscape border-10" data-slider-speed="0">
                                    <li v-for="(image, index) in images" :class="'image-' + index">
                                        <figure>
                                            <img
                                                style=" width: 100%; height: auto; /* or height in px */ background-size: cover; background-position: center center; -o-object-fit: cover; object-fit: cover;"
                                                alt="Brasserie Den Anker"
                                                :src="image.src">
                                        </figure>
                                    </li>
<!--                                    <li class="image-0">-->
<!--                                        <figure>-->
<!--                                            <img alt="Brasserie Den Anker" src="../assets/img/dish1.jpeg">-->
<!--                                        </figure>-->
<!--                                    </li>-->
<!--                                    <li class="image-1">-->
<!--                                        <figure>-->
<!--                                            <img alt="Brasserie Den Anker" src="../assets/img/dish2.jpeg">-->
<!--                                        </figure>-->
<!--                                    </li>-->
<!--                                    <li class="image-2">-->
<!--                                        <figure>-->
<!--                                            <img alt="Brasserie Den Anker" src="../assets/img/dish3.jpeg">-->
<!--                                        </figure>-->
<!--                                    </li>-->
<!--                                    <li class="image-3">-->
<!--                                        <figure>-->
<!--                                            <img alt="Brasserie Den Anker" src="../assets/img/dish5.jpeg">-->
<!--                                        </figure>-->
<!--                                    </li>-->
                                </ul>
                            </div>
                        </div>
                        <div class="item-page-content-wrap">
                            <div class="item-page-content">
                                <h1 class="entry-title"><a href="/menus" title="Menu&#8217;s">Menu&#8217;s</a></h1>
                                <h1>{{ data[1].title }}</h1>
                                <h3>{{ data[1].subtitle }}</h3>
                                    <p v-html="data[1].text"></p>
                                    <p>
                                        <router-link class="button" to="/menus">Onze menu&#8217;s</router-link>
                                    </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div class="slider-wrap showcase om-section block-4" id="slider-0be6f72eb378086c3fc3429d6ecee508">
                <ul
                    class="bg-small slider arrows arrows-hidden dots dots-default"
                    data-slider-speed="5000"
                    data-height="fixed-ratio"
                    data-att="35">
                    <li class>
                        <figure>
                            <img alt="ContactBrasserieDenAnker" src="../assets/img/DenAnker1-min.jpg">
                        </figure>

                    </li>
                </ul>
            </div>

            <div class="excerpt-wrap om-section block-5" id="excerpt-bb60b9fb1a6910cc38c45d70816c552d">
                <a class="anchor scrollto-anchor" id="scrollto-anchor-5"></a>
                <section class="excerpt">
                    <div class="content">
                        <h1>{{ data[2].title }}</h1>
                        <h3>{{ data[2].subtitle }}</h3>
                        <p v-html="data[2].text"></p>
                    </div>
                </section>
            </div>
        </div>

        <app-footer></app-footer>

        <svg id="icon-sprite-social">
            <defs>
                <symbol id="icon-facebook">
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>
    </div>
</template>

<script>
import AppFooter  from '../components/Footer'
import Navigation from '../components/Navigation'
// Import component
import Loading    from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    name: 'HomeNew',
    components: {Navigation, AppFooter, Loading},
    data() {
        return {
            isLoading: false,
            fullPage: true,
            data: [
                {title: '', subtitle: '', text: ''},
                {title: '', subtitle: '', text: ''},
                {title: '', subtitle: '', text: ''},
                {title: '', subtitle: '', text: ''},
                {title: '', subtitle: '', text: ''},
                {title: '', subtitle: '', text: ''},
            ],
            images: []
        }
    },
    mounted() {
        this.isLoading = true
        $(window).scrollTop(0)
        this.getWebsiteContent()
        this.getImages()
    },
    methods: {
        getWebsiteContent() {
            this.isLoading = true
            this.$axios.get('api/getWebsiteContentPage/Home').then(res => {
                if (res.data.error) {
                    //note: on error => mixin axiosCatch
                    this.errorMsg(res.data.error_msg)

                } else {
                    // note: on success
                    this.data      = res.data
                    this.isLoading = false
                }
            }).catch(err => {
                // note: mixin axiosCatch
                this.log(err)

            })
        },
        getImages() {
            this.isLoading = true
            this.$axios.get('api/getImages').then(res => {
                if (res.data.error) {
                    //note: on error => mixin axiosCatch
                    this.errorMsg(res.data.error_msg)

                } else {
                    // note: on success
                    this.images      = res.data
                    this.isLoading = false
                }
            }).catch(err => {
                // note: mixin axiosCatch
                this.log(err)

            })
        }
    }
}
</script>

<style scoped>

</style>
